<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Channel Partner Name</label>
                          <v-select v-model="cp" label="fullname" placeholder="None" :options="cpOptions">
                            <template #option="{ fullname, profile_image, username }">
                              <b-avatar :src="getprofileImage(profile_image)" v-if="fullname != 'All'" />
                              <span class="font-weight-bolder">
                                {{ fullname }}
                                <span v-if="fullname != 'All'">({{ username }})</span></span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr class="form-control" value="" :config="{
                            dateFormat: 'd/m/Y',
                            mode: 'range',
                          }" placeholder="Select Date" style="background-color: transparent"
                            @input="getdata($event)" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData" :disabled="loading">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
                <b-form-group class="resButtons mt-2">
                  <slot name="download">
                    <div class="d-flex align-items-center justify-content-end">
                      <b-button v-b-tooltip.hover.v-success title="Print" variant="gradient-success"
                        class="ml-2 btn-icon" @click="printData">
                        <feather-icon icon="PrinterIcon" />
                      </b-button>
                      <b-button v-b-tooltip.hover.v-success title="Pdf" variant="gradient-success"
                        class="ml-2 btn-icon">
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                      <b-button v-b-tooltip.hover.v-success title="Excel" variant="gradient-success"
                        class="ml-2 btn-icon">
                        <!-- @click="excelData" -->
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </div>
                  </slot>
                </b-form-group>
                <div id="channelpartner">
                  <div>
                    <h3>Channel Partner Report</h3>
                    <h5 style="margin: 0px" v-if="result && result.length == 2">
                      {{ result[0] }} to {{ result[1] }}
                    </h5>
                  </div>
                  <div class="overflow-auto">
                    <div class="channelpartner-table">
                      <TreeTable :value="data" class="w-100" :expandedKeys="expandedKeys" @toggle="onNodeToggle">
                        <Column field="fullname" header="Name" :expander="true" bodyClass="d-flex align-items-center">
                          <template #body="slotProps">
                            <div class="d-flex align-items-center" style="gap: 20px">
                              <b-avatar :src="getprofileImage(slotProps.node.profile_image)" />
                              <span>{{ slotProps.node.fullname }}</span>
                            </div>
                          </template>
                        </Column>
                        <Column field="amount" header="Business" bodyClass="text-right" headerClass="text-right">
                        </Column>
                        <Column field="rank" header="Percentage" bodyClass="text-right" headerClass="text-right">
                        </Column>
                      </TreeTable>
                    </div>
                  </div>
                </div>
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

import TreeTable from "primevue/treetable";
import Column from "primevue/column";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BAvatar,
    GoodTableColumnSearch,
    TreeTable,
    Column,
  },
  data() {
    return {
      loading: false,
      cp: "",
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      leadCategory: "",
      cpOptions: [],
      expandedKeys: {},
    };
  },
  mounted() {
    this.$store.dispatch("master/getChannelpartner");
  },
  watch: {
    "$store.state.master.channelPartners"(newValue, oldValue) {
      this.cpOptions = [{ fullname: "All", id: 0 }, ...newValue];
    },
  },
  methods: {
    onNodeToggle(event) {
      this.expandedKeys = event.value;
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result.length > 0 ? this.result[0] : "";
      this.enddate = this.result.length == 2 ? this.result[1] : "";
    },

    printData() {
      const divToPrint = document.getElementById("channelpartner");
      const newWin = window.open("", "_blank");

      // Get all the stylesheets in the current document
      const cssLinks = Array.from(
        document.querySelectorAll("link[rel='stylesheet'], style")
      );

      // Write the content and head (including stylesheets) to the new window
      newWin.document.write(`
          <html>
            <head>
              <title>Print Preview</title>
              ${cssLinks.map((link) => link.outerHTML).join("\n")}
              <style>

                  button {
                    visibility: hidden !important;
                  }

                  td {
                    padding: 5px;
                    border: 1px solid #e9ecef;
                  }

                  #channelpartner > div {
                    visibility: visible !important;
                  }

                  img {
                    border-radius: 50% !important;
                  }
                    h3,
                    h5 {
                      margin-top: 23px;
                      color: black;
                    }
                      tr td:not(:first-child),tr th:not(:first-child) {
                      text-align: end;
                      }

              </style>
            </head>
            <body>
              ${divToPrint.outerHTML}
            </body>
          </html>
        `);
      newWin.document.close();

      // Trigger the print dialog
      newWin.focus();
      newWin.print();
    },
    async searchData() {
      const data = {
        cp_id: this.cp ? this.cp.id : "",
        startdate: this.startdate ? this.startdate : "",
        enddate: this.enddate ? this.enddate : "",
      };
      this.loading = true;
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/channelpartner-report`,
      })
        .then((response) => {
          this.data = response.data.data;
          this.data.map((item) => {
            item.key = item.id;
            item.data = {
              fullname: item.fullname,
              amount: item.amount,
              rank: item.rank,
            };
            this.expandedKeys[item.id] = true;
            this.handleChild(item);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleChild(child) {
      if (child.children && child.children.length > 0) {
        child.children.map((item) => {
          item.key = item.id;
          item.data = {
            fullname: item.fullname,
            amount: item.amount,
            rank: item.rank,
          };
          this.expandedKeys[item.id] = true;

          this.handleChild(item);
        });
      }
    },
  },
};
</script>
<style>
#channelpartner>div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  visibility: hidden;
}
</style>
